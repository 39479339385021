import "core-js/modules/es.array.push.js";
import { uploadImgUrl } from '@/common/utils/config.js';
export default {
  data() {
    return {
      uploadImgUrl: uploadImgUrl,
      dialogImageUrl: '',
      previewImgDialog: false,
      body: '',
      contact: '',
      typeArr: [],
      //反馈类型
      curIndex: 0,
      img_list: [] //上传的图片列表
    };
  },

  mounted() {
    this.suggestionsType();
  },
  methods: {
    suggestionsType() {
      this.$http.allRecord({
        // type: 5
      }).then(res => {
        if (res.code == 1) {
          this.typeArr = res.data.types;
        }
      });
    },
    typeItemClick(index) {
      this.curIndex = index;
    },
    // 图片删除
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.previewImgDialog = true;
    },
    // 上传之前
    beforeUpload(file) {
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf('.'));
      console.log("文件类型", fileType);
      if (fileType === '.jpg' || fileType === '.png' || fileType === '.jpeg' || fileType === '.bmp' || fileType === '.gif') {} else {
        this.$message.error('不是,jpeg,.png,.jpg,.bmp,.gif,.mp4,.avi,.rm,.ram,.wmv文件,请上传正确的图片类型');
        return false;
      }
    },
    // 上传图片成功
    onChange(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.img_list = fileList;
    },
    // 去反馈记录
    toFeedbackRecord() {
      this.$router.push('/feedbackRecord');
    },
    // 提交反馈记录
    submitSuggestion() {
      if (this.flag) {
        return;
      }
      if (!this.body) {
        this.$message.error(this.$t('set.feedbackContentCannotEmpty'));
        return;
      }
      let newImgList = this.img_list.map(item => item.response.data[0].id).join(',');
      if (newImgList.length == 0) {
        this.$message.error(this.$t('tips.atLeastOnePhoto'));
        return;
      }
      if (!this.contact) {
        this.$message.error(this.$t('set.leaveYourContactInformation'));
        return;
      }
      let phone = /^1[3-9]\d{9}$/;
      if (!this.contact) {
        this.$message.error(this.$t('set.phoneText'));
        return false;
      }
      let type = this.typeArr[this.curIndex].id;
      this.flag = true;
      let data = {
        type,
        body: this.body,
        thumb: newImgList,
        contact: this.contact
      };
      this.$http.addSuggestion(data).then(res => {
        this.flag = false;
        if (res.code == 1) {
          this.$message.success(res.msg);
          setTimeout(() => {
            this.toFeedbackRecord();
          }, 2000);
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  }
};