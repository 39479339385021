var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "person_infor"
  }, [_c('div', {
    staticClass: "person_infor_left"
  }, [_c('div', {
    staticClass: "login_top"
  }, [_c('div', {
    staticClass: "login_title"
  }, [_vm._v(" " + _vm._s(_vm.$t('set.adviceFeedback')))])])]), _c('div', {
    staticClass: "person_infor_right",
    on: {
      "click": _vm.toFeedbackRecord
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('set.Historical_feedback')) + " ")])]), _c('div', {
    staticClass: "advice"
  }, [_c('div', {
    staticClass: "advice_title"
  }, [_vm._v(" " + _vm._s(_vm.$t('set.feedbackType')) + " ")]), _c('div', {
    staticClass: "advice_type"
  }, _vm._l(_vm.typeArr, function (item, index) {
    return _c('div', {
      key: index,
      class: ['advice_type_item', _vm.curIndex == index ? 'select' : 'un_select'],
      on: {
        "click": function ($event) {
          return _vm.typeItemClick(index);
        }
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]);
  }), 0), _c('div', {
    staticClass: "input_textarea"
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "autosize": true,
      "placeholder": _vm.$t('set.feedbackContentTextarea')
    },
    model: {
      value: _vm.body,
      callback: function ($$v) {
        _vm.body = $$v;
      },
      expression: "body"
    }
  })], 1), _c('div', {
    staticClass: "all_imgs"
  }, [_c('el-upload', {
    ref: "uploadImg",
    attrs: {
      "action": _vm.uploadImgUrl,
      "list-type": "picture-card",
      "on-preview": _vm.handlePictureCardPreview,
      "on-remove": _vm.handleRemove,
      "before-upload": _vm.beforeUpload,
      "on-change": _vm.onChange,
      "limit": 6,
      "multiple": true
    }
  }, [_c('i', {
    staticClass: "el-icon-plus"
  }), _c('div', {
    staticClass: "el-upload__tip",
    attrs: {
      "slot": "tip"
    },
    slot: "tip"
  }, [_vm._v(_vm._s(_vm.$t('pleaseUploadPhoto')))])]), _c('el-dialog', {
    attrs: {
      "visible": _vm.previewImgDialog,
      "modal": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.previewImgDialog = $event;
      }
    }
  }, [_c('img', {
    attrs: {
      "width": "100%",
      "src": _vm.dialogImageUrl,
      "alt": ""
    }
  })])], 1), _c('div', {
    staticClass: "advice_title"
  }, [_vm._v(" " + _vm._s(_vm.$t('set.leaveYourContactInformation')) + " ")]), _c('div', {
    staticClass: "input_content"
  }, [_c('el-input', {
    attrs: {
      "type": "",
      "placeholder": _vm.$t('set.phoneAndQQAndEmail')
    },
    model: {
      value: _vm.contact,
      callback: function ($$v) {
        _vm.contact = $$v;
      },
      expression: "contact"
    }
  })], 1), _c('div', {
    staticClass: "btn",
    on: {
      "click": _vm.submitSuggestion
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('submit')) + " ")])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };